import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navi = useNavigate()
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const handleRedirect = () => {
        navi('/terms-conditions')
    }

    return (
        <footer className="footer-area">
            <div className="container">
                <div className="footer-main">
                    <div className="footer-item order-3 order-lg-1">
                        <p>&copy; Copyright 2022 | All rights reserved</p>
                    </div>
                    <div className="social-icon order-1 order-lg-2">
                        <ul>
                            <li><a href="javascript:void(0)"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="javascript:void(0)"><i className="fab fa-linkedin-in"></i></a></li>
                            <li><a href="javascript:void(0)"><i className="fab fa-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div className="footer-item order-2 order-lg-3">
                        <ul>
                            <li><a onClick={() => scrollToSection("home")}>Privacy</a></li>
                            <li>|</li>
                            <li><a onClick={() => scrollToSection("media")}>Legal</a></li>
                            <li>|</li>
                            <li><a onClick={handleRedirect}>Terms of use</a></li>
                            <li>|</li>
                            <li><a onClick={() => scrollToSection("career")}>Blog</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
