import React from 'react';

const AboutSection = () => {
    return (
        <section className="about-area" id="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="about-left pr-xl-4">
                            <div className="about-upper">
                                <div className="about-head">
                                    <h2>About Us</h2>
                                    <h6>PROSFIC is your ultimate doorway to brand management solutions </h6>
                                </div>
                                <div className="about-item d-lg-none">
                                    <img src="images/logo-2.png" alt="" />
                                </div>
                                <p>PROSFIC stands for Public Relations & Organized Strategy For Individuals & Corporates, which goes with the tagline Rebrand Yourself. We believe in working as partners in growth for our clients. We make sure to come up with all the technical and marketing resources to build the best brands at one place.  </p>
                            </div>
                            <div className="about-cnt">
                                <p><strong>Our Approach :</strong> The best brands have their basics right. We believe that a fundamentally strong brand reflects in the revenue growth, and we work towards building it.End to end start up development and Brand establishment is our forte. Along with building our winning brand strategy for political parties and the corporates, we have worked to establish ourselves as the marketing and promotion tech leaders in the Blockchain & Crypto Industry. Most of the digital marketing companies in India don’t cater services to the developing crypto industry and political parties. They mostly stick to corporate, commercial and individual clients. Companies which cater services to future technologies like crypto and political parties are strictly industry specific.We are one of the very few companies in the country which does digital services to all the industries. We are probably the only company in India if not globally who could provide technical services along with digital marketing services for the blockchain industry. PROSFIC is the first company to introduce GUERILLA MARKETING to Block Chain Industry where we have a very successful module which provides end to end growth strategy for all clients.The</p>
                                <div className="more-content">
                                    <p>Metaverse is the future technology that's into building. From working in the Metaverse to gameplays, buying lands and building assets. An entirely new economy is developing. Prosfic helps you market and drive growth to your metaverse based business - with all the modern technology aids. We aim at bringing every solution at one place for our clients. We are also very few companies who could manage digital political campaigns along with booth management. We had a great successful run till now in this vertical.Above all PROSFIC thrives on brand establishment and not just growing the numbers.  We strongly believe that brand establishment would bring in revenue growth automatically. Focus on effectiveness of work and the byproduct (money/sales) follows.</p>
                                    <div className="read-morebtn">
                                        <a href="#"><i className="far fa-arrow-alt-circle-down"></i> Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about-right text-right d-none d-lg-block pl-lg-5">
                            <img src="images/logo-2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
