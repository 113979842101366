import React from 'react';

const ServiceSection = () => {
  return (
    <section className="service-area" id="service">
      <div className="container">
        <div className="service-upper">
          <h2>Our Services</h2>
        </div>
      </div>
      <div className="service-bg">
        <div className="container">
          <div className="service-main d-none d-xl-flex">
            <div className="service-item service-box1">
              <span><i className="fas fa-user"></i></span>
              <h4>Individual</h4>
              <p>We help our clients build and maximize their authority and influence through building a strong personal brand.</p>
              <ul>
                <li>An Impactful personal brand brings you :</li>
                <li>Audience Trust </li>
                <li>Revenue Streams</li>
                <li>Ideal Clients and Opportunities</li>
                <li>Authority </li>
                <li>Stand out the crowd </li>
              </ul>
              <p>We Understand you, your strengths and expertise. Based on that we build a customized Social Media and PR strategy that wins your audience and establishes your authority in the industry. </p>
            </div>
            <div className="service-item service-box2">
              <span><i className="fas fa-bullhorn"></i></span>
              <h4>Branding Services</h4>
              <p>More than just a logo, name, or color scheme, your brand is who you are as a company at a very fundamental level.</p>
              <p>Branding decides how customers feel about your business. When done right, it can generate an emotional response at every touchpoint. Businesses and individuals with a strong brand retain loyal customers for the long run and have an easier time appealing to new audiences because they already know what those companies are all about. </p>
            </div>
            <div className="service-item service-box3">
              <span><i className="fas fa-user-friends"></i></span>
              <h4>Corporates</h4>
              <p>We have the most experienced PR and Marketing professionals to grow your business and achieve the right image in front of your audience.</p>
              <p>We get your audience talking about your business and trust your expertise. We tell your stories to your audience in a way that they enjoy listening to them, relate and trust them - this takes your brand presence to a different level. </p>
              <p>We assure you the best results out of your marketing budget - and a smart position in the market. </p>
            </div>
            <div className="service-item service-box4">
              <span><i className="fas fa-dice-d20"></i></span>
              <h4>Blockchain & Metaverse </h4>
              <p>We are one of the first few companies who are working on the marketing and PR Campaigns for the Metaverse and Blockchain-based organizations.</p>
              <p>Be it an NFT Project, a De-fi token, or your own exchange - We have everything it takes you to thrive in the Crypto and Metaverse space! </p>
              <h6>Our Services Include : </h6>
              <ul>
                <li>PR management </li>
                <li>Telegram & Twitter Community Building</li>
                <li>Social Media Content Creation </li>
                <li>Influencer Promotions </li>
                <li>Featuring on top crypto media</li>
                <li>CoinGecko and Coin Market Cap listing </li>
                <li>Search Engine Optimization</li>
                <li>Customized tech for multiple channels promotion and more. </li>
              </ul>
            </div>
          </div>
          <div className="service-carosol d-xl-none">
            <div className="main-content2">
              <div id="owl-csel2" className="owl-carousel owl-theme">
                {/* Repeat the following structure for each service item */}
                <div>
                  <div className="service-item active">
                    <span><i className="fas fa-user"></i></span>
                    <h4>Individual</h4>
                    <p>We help our clients build and maximize their authority and influence through building a strong personal brand.</p>
                    <ul>
                      <li>An Impactful personal brand brings you :</li>
                      <li>Audience Trust </li>
                      <li>Revenue Streams</li>
                      <li>Ideal Clients and Opportunities</li>
                      <li>Authority </li>
                      <li>Stand out the crowd </li>
                    </ul>
                    <p>We Understand you, your strengths and expertise. Based on that we build a customized Social Media and PR strategy that wins your audience and establishes your authority in the industry. </p>
                  </div>
                </div>
                {/* Repeat the structure for other service items */}
              </div>
              <div className="owl-theme">
                <div className="owl-controls">
                  <div className="custom-nav owl-nav"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
