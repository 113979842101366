import React from 'react';

const CareerSection = () => {
    return (
        <section className="career-area" id='career'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="career-left pr-lg-3">
                            <h2>Careers</h2>
                            <p>Want to work with a company that's growing at a fast pace yet offers a healthy, growth-oriented environment for the entire team!</p>
                            <div className="career-item d-md-none">
                                <img src="images/career.png" alt="" />
                            </div>
                            <p>Think about how you can add value to the company or our clients.</p>
                            <h4>And write to us on</h4>
                            <form action="#" method="POST">
                                <input type="email" placeholder="Enter email address" />
                                <button type="button">Send</button>
                            </form>
                            <p>You can also check any open positions below and apply directly!</p>
                            <p>(NO POSITIONS AVAILABLE CURRENTLY)</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="career-right pl-lg-3 d-none d-md-block">
                            <img src="images/career.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CareerSection;
