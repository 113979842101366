import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div style={styles.container}>
            <h1>404 - Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <Link to="/">
                <button style={styles.button}>Go to Home</button>
            </Link>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    button: {
        margin: '10px',
        padding: '15px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s, transform 0.3s',
    },
    buttonHover: {
        backgroundColor: '#2980b9',
        transform: 'scale(1.05)',
    },
};

export default NotFound;
