import React from 'react'
import Header from './Header'
import AboutSection from './About'
import ServiceSection from './Service'
import MediaSection from './Media'
import CareerSection from './Career'
import ContactFormArea from './ContactForm'
import Footer from './Footer'
import ClientSection from './Client'

const Main = () => {
    return (
        <>
            <Header />
            <AboutSection />
            <ServiceSection />
            <MediaSection />
            <ClientSection />
            <CareerSection />
            <ContactFormArea />
            <Footer />
        </>
    )
}

export default Main