import React from 'react';

const MediaSection = () => {
  return (
    <section className="media-area" id="media">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-2 order-md-1">
            <div className="media-left pr-xl-5">
              <img src="images/mdia.png" alt="" />
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <div className="media-right">
              <h2>Media</h2>
              <p>Getting noticed for the hard work we put and strategies we build to execute for our very successful client campaigns, we feel appreciated and motivated.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediaSection;
