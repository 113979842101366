// import './App.css';
import { Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import ScrollToTop from './components/ScrollTop';
import Terms_Conditions from './components/Terms_Conditions';
import NotFound from './components/NotFound';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/terms-conditions" element={<Terms_Conditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <Main /> */}
      <ScrollToTop />
    </>
  );
}

export default App;
