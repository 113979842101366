import React from 'react';

const ClientSection = () => {
  return (
    <section className="client-area" id="client">
      <div className="container">
        <div className="client-main text-center">
          <h2>Clients</h2>
          <p>A great partnership is a two-way street — ideas and details flow openly and consistently, based on a foundation of mutual trust and respect for one another’s area of expertise — and our clients accept this belief.</p>
          <p>The finest and fruitful relationships are goal-oriented, and a long-term partnership has the value add of deep-rooted industry and company awareness and connections.</p>
          <p>Our client list speaks for itself. Since our earliest days, we’ve represented everything from start-ups to great political parties, Metaverse and Blockchain companies with that same growth-focused approach and dedication at the core of every commitment. Every day, since the beginning of PROSFIC, we’ve been devoting our hearts and minds to our clients' growth, achieving great successes and building lasting relationships.</p>
        </div>
        <div className="client-carosol">
          <div className="main-content">
            <div id="owl-csel1" className="owl-carousel owl-theme">
              {/* Repeat the following structure for each client item */}
              <div className="client-item">
                <img src="images/client-1.png" className="img-fluid" alt="" />
              </div>
              <div className="client-item">
                <img src="images/client-2.png" className="img-fluid client2" alt="" />
              </div>
              <div className="client-item">
                <img src="images/client-3.png" className="img-fluid client3" alt="" />
              </div>
              <div className="client-item">
                <img src="images/client-4.png" className="img-fluid" alt="" />
              </div>
              <div className="client-item">
                <img src="images/client-5.png" className="img-fluid client5" alt="" />
              </div>
              <div className="client-item">
                <img src="images/client-6.png" className="img-fluid" alt="" />
              </div>
              {/* Repeat the structure for other client items */}
            </div>
            <div className="owl-theme">
              <div className="owl-controls">
                <div className="custom-nav owl-nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientSection;
