import React from 'react';

const ContactFormArea = () => {
  const handleSubmit = (event) => {
    // Handle form submission logic here
    event.preventDefault();
    // You can add logic to handle form submission (e.g., send data to server)
  };

  return (
    <section className="contact-form-area" id='contact'>
      <div className="contact-formbg">
        <div className="container">
          <div className="contact-main">
            <h2>Want to fuel your Brand's Growth?</h2>
            <p>Drop your details here, and our team will Call You Back!</p>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-item">
                    <label htmlFor="a1">Name</label>
                    <input type="text" name="name" id="a1" placeholder="John Smith" required />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-item">
                    <label htmlFor="a2">Email Address</label>
                    <input type="email" name="email" id="a2" placeholder="Your email" required />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-item">
                    <label htmlFor="a3">Phone</label>
                    <input type="tel" name="phone" id="a3" placeholder="Your phone number" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-item">
                    <label htmlFor="a4">Company/Organization</label>
                    <input type="text" name="company" id="a4" placeholder="Your company/organization name" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-item">
                    <label htmlFor="a5">Website (Optional)</label>
                    <input type="url" name="website" id="a5" placeholder="Your website URL" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-item">
                    <label htmlFor="a6">Message</label>
                    <textarea name="sms" id="a6" cols="30" rows="5" placeholder="Enter your message" required></textarea>
                  </div>
                </div>
              </div>
              <div className="contact-item">
                <input type="submit" value="Send" name="contact" />
                {/* <h5 class="text-success"><?php if(isset($sc)){
                                echo $sc;
                                }?></h5> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFormArea;
