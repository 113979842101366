import React from 'react';

const Header = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    return (
        <header className="header-area" id='home'>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="#"><img src="images/logo.png" alt="" className="img-fluid" /></a>
                    <button className="hamburger hamburger--collapse navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className="navbar-nav ml-auto">
                            <li><a onClick={() => scrollToSection('home')}>HOME </a></li>
                            <li><a onClick={() => scrollToSection('about')}>ABOUT US </a></li>
                            <li><a onClick={() => scrollToSection('service')}>OUR SERVICES</a></li>
                            <li><a onClick={() => scrollToSection('media')}>MEDIA</a></li>
                            <li><a onClick={() => scrollToSection('client')}>CLIENTS</a></li>
                            <li><a onClick={() => scrollToSection('career')}>CAREERS</a></li>
                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li><a onClick={() => scrollToSection('contact')} className="button">CONTACT US </a></li>
                        </ul>
                    </div>
                </nav>
                <div className="header-cnt">
                    <h2>PROSFIC</h2>
                    <p>Rebrand Yourself</p>
                </div>
                <div className="header-item">
                    <img src="images/header.png" alt="" />
                </div>
            </div>
        </header>
    );
};

export default Header;
